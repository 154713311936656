import * as React from "react";
import "@src/scss/App.scss";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Stack from "react-bootstrap/Stack";
import { Routes } from '@components/routes';
import ButtonLink from '@components/ButtonLink';
import ModuleSection from '@components/modules/moduleWrapper';
import { StaticImage } from "gatsby-plugin-image";
//these two imports only for shorturls
import { navigate } from "gatsby";
import axios from "axios";

const getPropurl = async (handler, crmid) => {
	let result = [];
	var data = JSON.stringify({
		"query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id, publish: true}) { id, slug, search_type, department } }",
		"variables": { "crm_id": [crmid] }
	});
	var config = {
		method: 'post',
		url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
		},
		data: data
	};
	const res = await axios(config);
	result[0] = JSON.stringify(res.data);
	handler(result);
}

const NotFoundPage = (props) => {
	const [showtemplate, setShowtemplate] = React.useState(false);
	const [propdata, setPropdata] = React.useState('');

	React.useEffect(() => {
		let regex = "\[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9]{5}[a-zA-Z0-9]{8}";

		if (props?.location?.pathname) {
			let pathname = props?.location?.pathname;
			let crm_id = (props?.location?.pathname).match(regex);
			//console.log('propdata', propdata)
			if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
				getPropurl(setPropdata, crm_id[0]);
			} else if (propdata) {
				let property_data = JSON.parse(propdata);
				let property_arr = property_data.data.properties;
				if (property_arr.length > 0) {
					let property = property_arr[0];

					if (property.department == 'residential') {
						if (property.id) {
							if (property.search_type == 'sales') {
								navigate(`/property-for-sale/${property.slug}-${property.id}`, { replace: true });
							}
							else if (property.search_type == 'lettings') {
								navigate(`/property-for-rent/${property.slug}-${property.id}`, { replace: true });
							}
						}
					}
					if (property.department == 'commercial') {
						if (property.id) {
							if (property.search_type == 'sales') {
								navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`, { replace: true });
							}
							else if (property.search_type == 'lettings') {
								navigate(`/commercial-property-for-rent/${property.slug}-${property.id}`, { replace: true });
							}
						}
					}
					if (property.department == 'short_term') {
						if (property.id) {
							if (property.search_type == 'sales') {
								navigate(`/short-term-property-for-sale/${property.slug}-${property.id}`, { replace: true });
							}
							else if (property.search_type == 'lettings') {
								navigate(`/short-term-property-for-rent/${property.slug}-${property.id}`, { replace: true });
							}
						}
					}
					if (property.department == 'international_projects') {
						if (property.id) {
							if (property.search_type == 'international') {
								navigate(`/international-property-for-sale/${property.slug}-${property.id}`, { replace: true });
							}
						}
					}
				} else {
					setShowtemplate(true);
				}
			}
			if (pathname) {
				if (pathname.includes('/property-for-sale/')) {
					navigate(`/properties/for-sale/in-dubai/`, { replace: true });
				}
				else if (pathname.includes('/property-for-rent/')) {
					navigate(`/properties/for-rent/in-dubai/`, { replace: true });
				} else if (pathname.includes('/commercial-property-for-sale/')) {
					navigate(`/commercial-properties/for-sale/in-dubai/`, { replace: true });
				} else if (pathname.includes('/commercial-property-for-rent/')) {
					navigate(`/commercial-properties/for-rent/in-dubai/`, { replace: true });
				} else if (pathname.includes('/off-plan-property-for-sale/')) {
					navigate(`/off-plan-properties/for-sale/in-dubai/`, { replace: true });
				} else if (pathname.includes('/off-plan-project-for-sale/')) {
					navigate(`/off-plan-projects/for-sale/in-dubai/`, { replace: true });
				} else if (pathname.includes('/short-term-property-for-rent/')) {
					navigate(`/properties/short-term-for-rent/in-dubai/`, { replace: true });
				} else {
					setShowtemplate(true);
				}
			}
		}
	}, [propdata]);


	return (
		<>
			{showtemplate &&
				<Layout bodyClass="error-page position-relative list-property-page" headerClass="">
					<StaticImage
						src="../images/pattern.jpg"
						quality={50}
						formats={["auto", "webp"]}
						alt="Background Image - Exclusive Links"
						className="pattern-img"
					/>
					<Seo title="404: Not found" />
					<ModuleSection
						sectionClass={`position-relative z-index-5`}
					>
						<div className="error-page-section pt-88 pt-md-104 pt-lg-120">
							<Stack className="gap-16 gap-md-32">
								<h1 className="error-page-section-title h4 text-white">We’re sorry, the page you were looking for cannot be found.</h1>
								<p className="text-white">This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
								<Stack className="gap-16 gap-md-24 flex-md-row">
									<ButtonLink variant="secondary" manualLink="/" label="Back to Homepage" />
									<ButtonLink variant="secondary" manualLink={`${Routes.ContactUsPage}`} label="Contact" />
								</Stack>
							</Stack>
						</div>
					</ModuleSection>
				</Layout>
			}
		</>
	)
}

export default NotFoundPage
